<template>
  <div class="tickets">
    <div class="title">票券列表</div>
    <ym-table-list :data="data"
                   :columns="columns"
                   enableOperatorSlot
                   operatorWidth="240px"
                   :pageLayout="pageLayout"
                   :total="total"
                   :pageNumber="page"
                   @handlePageJump="handlePageJump">

      <template #topControl>
        <div class="top-control clearfix">
          <!-- 选择的状态条件标签 -->
          <div class="dropdownlist-tag"
               v-if="showSelectedTag">
            <template v-for="creator in selectedCreator">
              <el-tag closable
                      @close="clearselectedCreator"
                      disable-transitions
                      :key="creator">{{creator}}</el-tag>
            </template>
            <template v-for="status in selectedStatus">-
              <el-tag closable
                      @close="clearSelectedStatus"
                      disable-transitions
                      :key="status">{{status}}</el-tag>
            </template>
          </div>
          <!-- 顶部右侧搜索，刷新和新增 -->
          <div class="top-control-add">
            <el-button type="primary"
                       @click="showAdditionFn">新增</el-button>
          </div>
          <div class="top-control-refresh">
            <el-button icon="el-icon-refresh"
                       @click="handleRefresh">
            </el-button>
          </div>
          <div class="top-control-search">
            <el-input v-model="search"
                      placeholder="搜索票券名称"
                      prefix-icon="el-icon-search"
                      clearable
                      @clear="handleSearch"
                      @keyup.enter.native="handleSearch">
            </el-input>
          </div>
        </div>
        <!-- 选择菜单 -->
        <div class='dropdownlist'>
          <!-- 创建者选择菜单 -->
          <el-dropdown trigger="click"
                       @command="selectCreator">
            <el-button>创建者</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">全部</el-dropdown-item>
              <template v-for="(creator, index) in creatorList">
                <el-dropdown-item :command="index"
                                  :key="index">{{creator}}</el-dropdown-item>
              </template>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- 状态选择菜单 -->
          <el-dropdown trigger="click"
                       @command="selectStatus">
            <el-button>状态</el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="all">全部</el-dropdown-item>
              <el-dropdown-item command="notSend">未发放</el-dropdown-item>
              <el-dropdown-item command="sending">发放中</el-dropdown-item>
              <el-dropdown-item command="sendAll">已发完</el-dropdown-item>
              <el-dropdown-item command="expiry">已过期</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </template>

      <template #expiry="{row}">
        {{row.beginTime | timeFilter('YYYY.MM.DD')}} - {{row.endTime | timeFilter('YYYY.MM.DD')}}
      </template>
       <template #theme="{row}">
         <img :src="row.themeStyle&&row.themeStyle.thumb" alt="" style="width:50px; min-width:50px;">
      </template>

      <template #operatorCol="{row}">
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showDetail(row)">详情</el-button>
        <!-- 测试用 -->
        <el-button class="operator-btn ym-icon-liulan"
                   @click.stop="showChainInfo(row)">哈希</el-button>
        <!-- 测试用 -->
         <el-button class="operator-btn el-icon-edit"
        v-if="row.ticketType&&row.ticketType.functionType === '普通券' && !row.themeHide"
        @click.stop="showThemeModalFn(row)">编辑主题</el-button>

      </template>

    </ym-table-list>
    <tickets-addition v-if="showAddition"
                      @create="create"
                      @hide="hiddenModal('showAddition')"></tickets-addition>

    <!-- 测试用 -->
    <chain-info v-if="showInfo"
                :hashInfo="hashInfo"
                @CloseChainInfo="closeChainInfo"></chain-info>
    <!-- 测试用 -->
    <type-theme v-if="showThemeModal" :selectRow="selectRow"  @update="updateTheme" @hide="hiddenModal('showThemeModal')"></type-theme>

  </div>
</template>

<script>
import YmTableList from '@/components/TableList/index.vue'
import TicketsAddition from './ticketsAddition/index.vue'
import typeTheme from './typeTheme/index.vue'

import ChainInfo from '../chainInfo/index.vue'
import chainInfoMixin from '../chainInfo/chainInfo.js'

export default {

  // 测试用
  mixins: [chainInfoMixin],
  // 测试用

  components: {
    YmTableList,
    TicketsAddition,
    ChainInfo,
    typeTheme
  },
  data() {
    return {
      // 列表数据项
      data: [],
      // 列表表头配置项
      columns: [{
        label: '票券名称',
        prop: 'name',
        align: 'center'
      },
      {
        label: '总数(张)',
        prop: 'quantity',
        align: 'center'
      },
      {
        label: '已发放(张)',
        prop: 'useQuantity',
        align: 'center'
      },

      {
        label: '已核销(张)',
        prop: 'confirmQuantity',
        align: 'center'
      },
      {
        label: '剩余数量(张)',
        prop: 'remainderQuantity',
        align: 'center'
      },
      {
        label: '包含记账单位(个)',
        prop: 'accountingPerTicket',
        align: 'center'
      },
      {
        label: '所属记账单位',
        prop: 'accountingName',
        align: 'center'
      },
      {
        label: '创建者',
        prop: 'creator',
        align: 'center'
      },
      {
        label: '状态',
        prop: 'status',
        align: 'center'
      },
      {
        label: '有效期',
        prop: 'expiry',
        align: 'center',
        enableColSlot: true,
        width: '200px'
      },
      {
        label: '主题',
        prop: 'theme',
        align: 'center',
        enableColSlot: true,
        width: '60px'
      }
      ],
      pageLayout: 'prev, pager, next, jumper',
      total: 20, // 数据总数
      size: 10,
      page: 1,
      selectedStatus: null, // 已选择的状态
      creatorList: [],
      selectedCreator: null,
      showSelectedTag: false, // 是否存在已选择的状态
      search: '', // 搜索内容,
      selectRow: {},
      showThemeModal: false, // 主题
      showAddition: false // 显示新增记账单位窗口
    }
  },
  filters: {
    url (val) {
      return window.BASE_URL + val
    }
  },
  watch: {
    selectedStatus() {
      this.showSelectedTag = this.hasSelected()
    },
    selectedCreator() {
      this.showSelectedTag = this.hasSelected()
    }
  },
  mounted() {
    this.getList()
    this.getCreatorList()
    // setTimeout(() => {
    //   this.creatorList = [{
    //     id: 0,
    //     name: '创建者1',
    //     command: 1
    //   }, {
    //     id: 1,
    //     name: '创建者2',
    //     command: 2
    //   }, {
    //     id: 2,
    //     name: '创建者3',
    //     command: 3
    //   }]
    // }, 500)
  },
  methods: {
    // 对搜索框及状态选择标签进行搜索
    handleSearch() {
      this.page = 1
      this.getList()
    },
    // 清空搜索条件(包括状态选择和搜索文本)
    handleRefresh() {
      this.selectedStatus = null
      this.selectedCreator = null
      this.search = ''
      this.handleSearch()
    },
    showAdditionFn() {
      this.showAddition = true
      this.$bus.$emit('blurScreen')
    },
    showThemeModalFn(row) {
      this.selectRow = row
      this.showThemeModal = true
      this.$bus.$emit('blurScreen')
    },
    hiddenModal(type) {
      this.$bus.$emit('clearBlur')
      this[type] = false
    },
    // 是否存在筛选条件
    hasSelected() {
      if (this.selectedStatus || this.selectedCreator) {
        return true
      } else {
        return false
      }
    },
    // 清楚筛选条件
    clearSelectedStatus() {
      this.selectedStatus = null
      this.handleSearch()
    },
    // 清楚筛选条件
    clearselectedCreator() {
      this.selectedCreator = null
      this.handleSearch()
    },
    // 筛选状态条件
    selectStatus(command) {
      switch (command) {
        case 'all':
          this.selectedStatus = null
          break
        case 'notSend':
          this.selectedStatus = []
          this.selectedStatus.push('未发放')
          break
        case 'sending':
          this.selectedStatus = []
          this.selectedStatus.push('发放中')
          break
        case 'sendAll':
          this.selectedStatus = []
          this.selectedStatus.push('已发完')
          break
        case 'expiry':
          this.selectedStatus = []
          this.selectedStatus.push('已过期')
          break
        default:
          this.selectedStatus = null
          break
      }
      this.handleSearch()
    },
    // 筛选状态条件
    selectCreator(command) {
      if (command === 'all') {
        this.selectedCreator = null
      } else {
        this.selectedCreator = []
        this.selectedCreator.push(this.creatorList[command])
      }
      this.handleSearch()
    },
    // 处理新增票券
    create(data) {
      this.$http.ticket.addTicket({
        name: data.name,
        accountingId: data.type,
        accountingPerTicket: data.contain,
        quantity: data.amount,
        type: data.ticketType,
        detailPictures: data.detailPictures
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.hiddenModal('showAddition')
          this.handleRefresh()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    showDetail(row) {
      this.$router.push({
        name: 'tickets-detail',
        query: {
          ticketId: row.id
        }
      })
    },
    // 处理页码跳转
    handlePageJump(page, pageSize) {
      console.log(page)
      this.page = page
      this.getList()
    },
    getList() {
      this.$http.ticket.getTicketList({
        size: this.size,
        page: this.page,
        ticketName: this.search,
        statusList: this.selectedStatus,
        creatorList: this.selectedCreator
      }).then(res => {
        if (res.data.code === 0) {
          res.data.data.records.forEach(item => {
            if (item.themeStyle) {
              item.themeStyle.thumb = window.BASE_URL + item.themeStyle.thumb
            }
          })
          this.data = res.data.data.records
          this.total = res.data.data.total
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    getCreatorList() {
      this.$http.ticket.getCreatorList().then(res => {
        if (res.data.code === 0) {
          this.creatorList = res.data.data
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    updateTheme (data) {
      this.$http.ticket.updateTicket({
        id: data.id,
        themeId: data.themeId,
        detailPictures: []
      }).then(res => {
        if (res.data.code === 0) {
          this.$message({
            type: 'success',
            message: res.data.message
          })
          this.hiddenModal('showThemeModal')
          this.handleRefresh()
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    }
  }

}
</script>

<style lang="scss">
.tickets {
  .title {
    font-size: 20px;
    font-weight: 600;
    color: $--color-text-primary;
    margin-bottom: 30px;
  }
  .top-control {
    margin-top: 16px;
    margin-bottom: 16px;
    .dropdownlist-tag {
      float: left;
      margin-left: 32px;
      .el-tag {
        // position: relative;
        // width: 110px;
        height: 40px;
        font-size: 14px;
        line-height: 41px;
        background-color: $--color-primary-light-9;
        border: none;
        border-radius: 6px;
        color: $--color-text-primary;
        padding-left: 20px;
        padding-right: 12px;
        margin-right: 10px;
        cursor: default;
        .el-icon-close {
          // position: absolute;
          // right: 11px;
          // top: 50%;
          // margin-top: -8px;
          color: $--color-text-primary;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
    &-search {
      float: right;
      width: 240px;
      margin-right: 24px;
      .el-input {
        border-radius: 6px;
      }
    }
    &-refresh {
      float: right;
      margin-right: 24px;
      .el-button {
        padding: 8px 11px;
        .el-icon-refresh::before {
          font-size: 20px;
        }
      }
    }
    &-add {
      float: right;
      margin-right: 30px;
      font-size: $--font-size-medium;
      font-weight: $--font-weight-primary;
    }
  }
  .dropdownlist {
    padding-top: 19px;
    padding-bottom: 19px;
    border-top: 1px solid #e5e8f3;
    .el-button {
      width: 90px;
      height: 41px;
      margin-left: 32px;
    }
  }
}
.tag {
  margin-right: 10px;
}
</style>
