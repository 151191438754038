<template>
  <div class="tickets-addition">
    <el-dialog :append-to-body="true"
               :visible="visible"
               destroy-on-close
               :close-on-click-modal="false"
               :show-close="false"
               :width="dialogWidth"
               @close="handleCancel"
               top="20vh"
               class="tickets-addition-dialog">
      <div slot="title"
           class="tickets-addition-form__header">
        <span>编辑主题</span>
      </div>
      <el-form ref="form"
               :model="form"
               label-position="right"
               label-width="auto"
               label-suffix="："
               :rules="rules">
        <div class="tickets-addition-form__body">
          <el-form-item label="票券主题"
                        prop="themeId">
                <div class="preview-thumb">
                  <div  v-for="(item,index) in list" :key="index">
                     <img :src="item.content.thumb" alt="" @click="selectItemFn(item,index)" :class="currentIndex === index? 'select': 'no-select'">
                  </div>
                </div>
                <div class="preview-theme" :style="{'background':'url('+list[currentIndex].content.background+') no-repeat', 'backgound-size': '100%'}" v-if="currentIndex!=null">
                    <div class="top">
                        <img class="logo" src="../../../assets/img/logo-1.png" alt="">
                        <span class="create-name">{{selectRow.creator}}</span>
                        <span class="send-name">赠送人：李三</span>
                    </div>
                    <div class="center">
                        <div class="type-name">{{selectRow.ticketType.typeName || '历史券xxxxx'}}</div>
                        <div class="ticket-name">{{selectRow.name}}</div>
                        <div class="expiry-time">有效期至：{{selectRow.endTime | timeFilter('YYYY-MM-DD')}}</div>
                    </div>
                    <div class="bottom">
                      <div class="video">
                        <img src="../../../assets/img/video.png" alt="">
                        <span>赠送故事</span>
                      </div>
                      <img :src="list[currentIndex].content.sendButton" alt="" class="send" v-if="selectRow.ticketType.donor.length>0">
                      <img :src="list[currentIndex].content.exchangeButton" alt="" class="send" v-if="selectRow.ticketType.detail.length>0">
                    </div>
                </div>
          </el-form-item>
        </div>
      </el-form>
      <div slot="footer"
           class="tickets-addition-form__footer">
        <el-button class="cancel"
                   @click="handleCancel">取消</el-button>
        <el-button type="primary"
                   class="submit"
                   @click="handleSubmit('form')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { deepClone } from '@/utils/deepClone.js'
export default {
  props: {
    selectRow: Object
  },
  data() {
    return {
      // 新建的票券类别相关信息
      form: {
        id: '',
        themeId: '',
        detailPictures: []
      },
      options: [],
      // 校验规则
      rules: {
      },
      dialogWidth: '0',
      visible: false,
      loading: false,
      list: [],
      currentIndex: null
    }
  },
  filters: {
    filterUrl (val) {
      return window.BASE_URL + val
    }
  },
  mounted() {
    this.clearForm()
    this.visible = true
    this.setDialogWidth()
    this.getList()
    // 监听浏览器窗口大小变化，用于新增记账单位窗口的自适应
    window.addEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  // 移除事件监听
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      if (this.visible) {
        return this.setDialogWidth()
      }
    }, false)
  },
  watch: {
    visible() {
      if (this.visible) {
        this.setDialogWidth()
      }
    },
    'form.themeId'(newVal, old) {

    }
  },
  methods: {
    // 清空窗口的输入内容
    clearForm() {
      this.form.themeId = ''
    },
    // 点击取消按钮时的操作
    handleCancel() {
      this.visible = false
      this.$emit('hide')
    },
    // 点击确定按钮时的内容校验和数据弹出
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = deepClone(this.form)
          this.$emit('update', data)
        } else {
          return false
        }
      })
    },
    // 设置不同屏幕下的窗口宽度
    setDialogWidth() {
      let windowWidth = window.innerWidth
      if (windowWidth <= 320) {
        this.dialogWidth = '99%'
      } else if (windowWidth <= 414) {
        this.dialogWidth = '90%'
      } else if (windowWidth > 414) {
        this.dialogWidth = '550px'
      }
    },
    // 获取权限列表
    getList() {
      this.$http.ticketType.getTicketThemeList().then(res => {
        if (res.data.code === 0) {
          res.data.data.forEach(item => {
            // item.content = JSON.parse(item.content)
            item.content.background = window.BASE_URL + item.content.background
            item.content.thumb = window.BASE_URL + item.content.thumb
            item.content.sendButton = window.BASE_URL + item.content.sendButton + '?v=123'
            item.content.exchangeButton = window.BASE_URL + item.content.exchangeButton + '?v=123'
          })
          console.log(res.data.data)
          this.list = res.data.data
          this.selectItemFn(this.list[0], 0)
        } else {
          this.$message({
            type: 'error',
            message: res.data.message
          })
        }
      })
    },
    selectItemFn (item, index) {
      this.currentIndex = index
      this.form.themeId = item.content.id
      this.form.id = this.selectRow.id
    }

  }
}
</script>

<style lang="scss">
.tickets-addition-dialog {
  .el-dialog {
    box-shadow: 0px 10px 25px 0px rgba(36, 37, 42, 0.15);
    border-radius: 4px;
    overflow: hidden;
    &__header {
      padding: 0;
    }
    &__body {
      padding: 49px 40px 24px 36px;
    }
    &__footer {
      padding: 0;
    }
  }
}
.tickets-addition {
  &-form {
    &__header {
      height: 48px;
      line-height: 48px;
      padding-left: 23px;
      font-size: 16px;
      font-weight: 600;
      background: #eff1f4;
      user-select: none;
    }
    &__body {
      font-size: 14px;
      .el-form-item {
        margin-bottom: 20px;
        &__label {
          color: #222b45;
          font-weight: 600;
          user-select: none;
        }
        .el-input {
          width: 292px;
        }
        .el-date-editor {
          width: 260px;
          .el-range-separator {
            width: 12%;
            font-weight: 600;
            padding: 0;
          }
        }
      }
      .ticketType {
        margin-top: 68px;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-end;
      height: 52px;
      padding: 8px 20px;
      background: rgba($color: #eff1f4, $alpha: 0.5);
      .el-button {
        font-weight: 600;
        padding: 8px 20px;
        & + .el-button {
          margin-left: 16px;
        }
      }
    }
    &__info {
      position: absolute;
      // top: 410px;
      // left: 150px;
      top: 409px; // 非遗票券选择存在时使用
      left: 150px; // 非遗票券选择存在时使用
      user-select: none;
      .balance {
        color: $--color-danger;
      }
      .info {
        display: inline-block;
        font-size: $--font-size-extra-small;
        margin-right: 40px;
        color: #b3b4ba;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 414px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 235px;
        }
      }
    }
  }
  .tickets-addition-dialog {
    .el-dialog__body {
      padding: 41px 10px 20px;
    }
  }
}

@media screen and (max-width: 375px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 200px;
        }
        .el-date-editor {
          width: 200px;
        }
      }
    }
  }
  .tickets-addition-dialog {
    .el-dialog__body {
      padding: 41px 5px;
    }
  }
}

@media screen and (max-width: 320px) {
  .tickets-addition-form__body {
    .el-form-item {
      .el-form-item__content {
        .el-input {
          width: 183px;
        }
        .el-date-editor {
          width: 183px;
        }
      }
    }
  }
}
.no-select{
  cursor: pointer;
  padding: 2px;
  border: 2px solid transparent;

}
.select{
  cursor: pointer;
  padding: 2px;
  border: 2px solid $--color-primary;
}
.preview-thumb{
  user-select: none;
  width:336px;
  display: flex;
  justify-content: space-between;
}
.preview-theme{
  user-select: none;
  width:336px;
  height:175px;
  .top{
    font-size:  $--font-size-small;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba(255,255,255,0.6);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 17px;
    position: relative;
    border-bottom:1px dashed #fff;
    .logo{
      width:38px;
      height:38px;
      margin-right:10px;
    }
    .create-name{
      flex-grow: 1.0;
    }
    .send-name{

    }
    &:before{
      content: '';
      width:12px;
      height:12px;
      position: absolute;
      bottom:-6px;
      left:-6px;
      background-color: rgb(242, 243, 245);
      border-radius: 50%;
      border: none;
    }
    &:after{
      content: '';
       width:12px;
      height:12px;
      position: absolute;
      bottom:-6px;
      right:-6px;
      background-color: rgb(242, 243, 245);
      border-radius: 50%;
      border: none;
    }
  }
  .center{
        padding: 0 17px;
    .type-name{
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
    }
    .ticket-name{
      font-size: $--font-size-small;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(255,255,255,0.3);
      line-height: 20px;
    }
    .expiry-time{
      font-size: $--font-size-small;
      font-family: PingFang SC;
      font-weight: 400;
    color: rgba(255,255,255,0.3);
      line-height: 20px;

    }
  }
  .bottom{
     padding: 0 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .video{
      color:#fff;
      font-size:$--font-size-small;
      flex-grow: 1.0;
      display: flex;
      align-items: center;
      img{
        width:20px;
        height:20px;
        margin-right:5px;
      }
    }
    .send{
      width:60px;
      margin-left:17px;
    }
  }
}
</style>
